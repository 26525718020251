.reasons {
  color: #fff;
  padding: 0 2rem;
  display: flex;
  gap: 2rem;
}

.reasons__left {
  flex: 1 1;
  display: grid;
  gap: 1rem;
  grid-auto-rows: auto;
  grid-template-columns: repeat(2, 1fr);
}

.reasons__left > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.reasons__left > :nth-child(1) {
  grid-row: 1/3;
  grid-column: 1/2;
  height: 27.5rem;
}

.reasons__left > :nth-child(2) {
  grid-column: 2/4;
  grid-row: 1/2;
  height: 13rem;
}

.reasons__left > :nth-child(3) {
  grid-column: 2/3;
  grid-row: 2/3;
  height: 13rem;
}

.reasons__left > :nth-child(4) {
  grid-column: 3/4;
  grid-row: 2/3;
  height: 13rem;
}

.reasons__right {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.reasons__right > :nth-child(1) {
  text-transform: uppercase;
  color: var(--orange);
  font-weight: bold;
}

.reasons__right ul li {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.reasons__right ul {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.reasons__right ul li > img {
  width: 2rem;
}

.reasons__right ul li > p {
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: bold;
}

.reasons__right {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.reasons__right .parters {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.reasons__right .parters > div {
  display: flex;
  gap: 1rem;
}

.reasons__right .parters img {
  width: 2.5rem;
}

@media screen and (max-width: 768px) {
  .reasons {
    flex-direction: column;
    gap: 1rem;
  }
  .reasons__left > :nth-child(1) {
    height: 18rem;
  }
  .reasons__left > :nth-child(2) {
    height: 8rem;
  }
  .reasons__left > :nth-child(3) {
    height: 9rem;
  }
  .reasons__left > :nth-child(4) {
    height: 9rem;
  }
}

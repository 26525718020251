.plan {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  background-color: var(--caloryCard);
  width: 18rem;
}

.plan > svg {
  width: 2rem;
  height: 2rem;
  fill: var(--orange);
}

.plan > p {
  font-size: 2rem;
  font-weight: bolder;
}

.plan .features ul li {
  list-style: none;
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
}
.plan .features ul li > img {
  width: 1rem;
}

.plan > :nth-child(5) {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.plan > :nth-child(5) > img {
  width: 1rem;
}

.plan > .btn {
  background-color: white;
}

@media screen and (max-width: 768px) {
  .plan {
    width: 90%;
    gap: 1rem;
    align-self: center;
    transform: none;
  }
}

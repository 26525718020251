* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  color: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
p {
  margin: 0;
  padding: 0;
  font-size: 1rem;
  font-weight: 200;
  letter-spacing: 2px;
  line-height: 1.4rem;
}

h2 {
  font-size: 3rem;
  text-transform: uppercase;
  font-weight: bolder;
}

h1 {
  font-size: 4.3rem;
  text-transform: uppercase;
  font-weight: bold;
}

li {
  list-style: none;
}

@media screen and (max-width: 768px) {
  p {
    font-size: 1rem;
    letter-spacing: 1px;
    line-height: 1.2rem;
  }
  h2 {
    font-size: 1.5rem;
  }
  h1 {
    font-size: 2rem;
  }
}
